<script setup>
import {
  noteStatus,
  transformArray
} from "@/components/ExternalApps/WatchDog/utils/utils";
import { formatDate } from "@/components/Tools/helperFunctions";
import { computed, defineProps, onMounted, ref } from "vue";
import { usePresetService } from "@/components/ExternalApps/WatchDog/composables/usePresetService";
import { shopModulesNotificationEnv } from "@/components/ExternalApps/WatchDog/config/general";
import Reporting from "@/components/Workflows/Reporting/reporting";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useStore } from "@/core/services/store";

const props = defineProps({
  projectId: {
    type: String,
    default: ""
  }
});

const store = useStore();
const { preset, getPresetByName } = usePresetService();

const module = computed(() => preset.value?.value?.modulename);
const workflowId = computed(() => shopModulesNotificationEnv[module.value]);
const isFetching = computed(() =>
  store.getters["loadingQueue/showLoadingBar"]("loadIteration", props.projectId)
);
const iterations = ref();

function loadIterations() {
  const params = {
    filter: [],
    page: 1,
    perPage: 5
  };

  const headers = {
    "x-project-id": props.projectId
  };

  addEventToLoadingQueue({
    key: "loadIteration",
    group: props.projectId
  });
  Reporting.getIterations(workflowId.value, params, headers, true)
    .then(response => {
      iterations.value = transformArray(response.data);
    })
    .finally(() => {
      removeEventFromLoadingQueue({
        key: "loadIteration",
        group: props.projectId
      });
    });
}

onMounted(async () => {
  await getPresetByName("salesChannelConfig", props.projectId);
  if (workflowId.value) loadIterations();
});
</script>

<template>
  <div>
    <div v-if="isFetching">
      <b-skeleton
        v-for="n in 5"
        :key="'skeleton-integration-' + n"
        :class="{ 'mt-2': n !== 1 }"
        height="24px"
        width="100%"
      ></b-skeleton>
    </div>
    <div v-else-if="iterations?.length > 0">
      <div
        v-for="(item, i) of iterations"
        :key="i"
        :class="{ 'mt-2': i !== 0 }"
        class="d-flex align-items-center"
      >
        <i :class="noteStatus[item.level] ?? noteStatus.default" />
        <div class="ml-2">{{ item.contentIdentifier }}</div>
        <div class="ml-auto">
          <span class="badge badge-light">{{ formatDate(item.date) }}</span>
        </div>
      </div>
    </div>
    <div v-else>{{ $t("watchDog.noNotifications") }}</div>
  </div>
</template>

<style lang="scss" scoped></style>
