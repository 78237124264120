import i18n from "@/core/plugins/vue-i18n";

export const noteStatus = {
  success: "fas fa-circle yedi-green",
  error: "fas fa-circle yedi-red",
  notice: "fas fa-circle yedi-blue",
  warning: "fas fa-circle yedi-orange",
  default: "fas fa-circle"
};

// Transforms given process iteration array into usable data for the notification area
export function transformArray(arr) {
  return arr.map(obj => {
    const [level, identifier] = obj.content_identifier?.split(":");

    return {
      level: level?.trim()?.toLowerCase() ?? "default",
      contentIdentifier:
        identifier?.trim() ?? i18n.t("watchDog.defaultNotification"),
      date: obj.created_at
    };
  });
}
