<script setup>
import { useProjectService } from "@/components/ExternalApps/WatchDog/composables/useProjectService";
import WatchdogHeader from "@/components/ExternalApps/WatchDog/components/WatchDogHeader.vue";
import WatchdogProject from "@/components/ExternalApps/WatchDog/components/WatchDogProject.vue";
import { useWatchDogFormService } from "@/components/ExternalApps/WatchDog/composables/useWatchDogFormService";
import { onMounted, ref } from "vue";
import { useStore } from "@/core/services/store";
import {
  LOAD_PROJECTS,
  RESET_PROJECTS
} from "@/core/services/store/switchArea.module";
import Loader from "@/components/Tools/Loader.vue";

const { projects, xentralProjectId } = useProjectService();
const { loadForms } = useWatchDogFormService();

const isLoading = ref(true);

onMounted(async () => {
  await useStore().dispatch(RESET_PROJECTS);
  await useStore().dispatch(LOAD_PROJECTS);
  await loadForms();
  isLoading.value = false;
});
</script>

<template>
  <div>
    <Loader v-if="isLoading || !xentralProjectId"></Loader>
    <template v-else>
      <template v-if="!xentralProjectId">
        <span>{{ $t("watchdog.noXentralProjectId") }}</span>
      </template>
      <template v-else>
        <WatchdogHeader />
        <div
          v-for="(project, i) of projects"
          :key="i"
          class="mb-4 project-listing"
        >
          <WatchdogProject :project="project" />
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>
