<script setup>
import Header from "@/components/Tools/Header/Header.vue";
import { computed, onMounted, ref } from "vue";
import i18n from "@/core/plugins/vue-i18n";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import ProcessManager from "@/components/Workflows/processManager";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";
import { Error } from "@/core/plugins/swal";
import { useProjectService } from "@/components/ExternalApps/WatchDog/composables/useProjectService";
import { useStore } from "@/core/services/store";

const { projects, xentralProjectId } = useProjectService();
const store = useStore();

const isDisabled = ref(false);
const headerItems = computed(() => {
  return [
    {
      type: "button",
      title: i18n.t("watchDog.loadProjects"),
      emit: "trigger",
      disabled: isDisabled.value
    }
  ];
});

async function triggerWorkflow(noError = false) {
  if (isDisabled.value) {
    return;
  }

  isDisabled.value = true;
  addEventToLoadingQueue({ key: "linkOnlineShops" });

  let params = {
    presetId: process.env.VUE_APP_WATCHDOG_APP_PRESET_ID
  };
  let optionalHeaders = {
    "x-project-id": xentralProjectId.value
  };

  await ProcessManager.run(
    process.env.VUE_APP_WATCHDOG_APP_EXTERNAL_WORKLFLOW_ID,
    {
      params: params,
      dispatchImmediately: true
    },
    optionalHeaders
  )
    .then(response => {
      let payload = {
        key: "linkOnlineShops",
        prefix: "watchDog"
      };

      if (response.data.status === "process.stopped") {
        payload = {
          ...payload,
          type: "success",
          name: "shopLinked"
        };
      } else {
        payload = {
          ...payload,
          type: "error",
          name: "shopLinkedError"
        };
      }
      removeEventFromLoadingQueue(payload);
    })
    .catch(error => {
      if (noError) return;
      Error(error);
    })
    .finally(() => {
      store.dispatch(LOAD_PROJECTS);
      isDisabled.value = false;
    });
}

onMounted(() => {
  // Check all necessary variables/params
  isDisabled.value = !(
    xentralProjectId.value &&
    process.env.VUE_APP_WATCHDOG_APP_EXTERNAL_WORKLFLOW_ID &&
    process.env.VUE_APP_WATCHDOG_APP_PRESET_ID
  );

  // Initial loading of integrations if there are none
  if (projects.value?.length < 1) {
    triggerWorkflow(true);
  }
});
</script>

<template>
  <Header :items="headerItems" @trigger="triggerWorkflow"></Header>
</template>

<style lang="scss" scoped>
.logo {
  height: 38px;
}
</style>
